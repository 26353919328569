import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { Company } from "../models/simskillsModels";
import { ModuleEnum } from "../utils/enums/module.enum";
import { COMPANY_MODULES } from "../settings/module.settings";
import { SIMULATION_PROGRESS_SETTINGS } from '../modules/admin/user/settings/simulation-progress.settings';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  constructor(private firebaseService: FirebaseService) {
  }

  public getCompanyByNameLowerCase(nameLowerCase: string): Promise<any> {
    return this.firebaseService.getDocumentByProperty('/companies/', 'name_lowercase', nameLowerCase);
  }

  public getCompanies() {
    return this.firebaseService.getDocument('/companies/');
  }

  public getCompany(companyId: string) {
    return this.firebaseService.getDocument('/companies/' + companyId);
  }

  public async addCompany(company: Company) {
    return await this.firebaseService.addDocument(`/companies/`, company);
  }

  public async removeCompany(companyId: string) {
    return await this.firebaseService.deleteDocument('/companies', companyId);
  }

  public async editCompany(companyId: string, updatedData: Partial<Company>) {
    return await this.firebaseService.updateDocument('/companies', companyId, updatedData);
  }

  public async getCompanyModules(companyId: string): Promise<ModuleEnum[]> {
    const companyModules = await this.firebaseService.getDocument(`companies/${companyId}/settings/availableModules`);
    if (companyModules?.length > 0) {
      return companyModules;
    }
    return COMPANY_MODULES;
  }

  public async getCompanyScore(companyId: string): Promise<number> {
    const score = await this.firebaseService.getDocument(`companies/${companyId}/settings/simulations/score`);
    if (score === null) {
      return SIMULATION_PROGRESS_SETTINGS.score;
    }
    return score;
  }
  
  public getSettingByPath = (company: string, path: any): Promise<any> => {
    const url = `/companies/${company}/settings/${path}`;
    return this.firebaseService.getDocument(url);
  }
  
}
