import { Pipe, PipeTransform } from '@angular/core';
import { RoleType } from '../../utils/custom-types';
import { AuthService } from '../../services/auth.service';

@Pipe({
  name: 'role'
})
export class RolePipe implements PipeTransform {
  constructor(
    private authService: AuthService,
  ) {
  }
  transform(roles: RoleType[] | string[]): boolean {
    const userRole = this.authService.getUserRole();
    return roles.some(role => role === userRole);
  }

}
