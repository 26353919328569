import { Component, EventEmitter, HostListener, OnInit, Output, } from '@angular/core';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { RolePipe } from '../../shared/pipes/role.pipe';
import { ComponentBase } from '../../utils/component-base';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        width: '170px',
      })),
      state('closed', style({
        opacity: 0,
        width: '0px',
      })),
      transition('open => closed', [
        animate('0.7s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ])
  ],
})
export class SideNavComponent extends ComponentBase implements OnInit {
  showFullMenu = true;
  fixedFullMenu = true;
  menuItems: Array<any> = [];
  @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  collapsed: boolean = false;
  screenWidth: number = 0;

  constructor(
    private rolePipe: RolePipe
  ) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setInnerScreenWidth();
    if (this.screenWidth <= 1600) {
      this.collapsed = true;
      this.onToggleEmit();
    }
  }

  ngOnInit() {
    if (this.rolePipe.transform(['admin'])) {
      this.menuItems = [
        {title: 'Users', icon: 'group', route: '/admin-users-table'},
        {title: 'Reports', icon: 'assignment', route: '/admin-reports'},
        {
          title: 'Schedule simulation',
          icon: 'event',
          route: '/simulation-peers-add',
        },
        {title: 'Simulations', icon: 'list_alt', route: '/simulations/avatar'},
        {title: 'Companies', icon: 'manage_accounts', route: '/companies'},
        {title: 'Modules', icon: 'assignment_ind', route: '/modules'},
        {title: 'Assessments', icon: 'psychology_alt', route: '/assessments'},
      ];
    }
    if (this.rolePipe.transform(['manager'])) {
      this.menuItems = [
        {title: 'Users', icon: 'group', route: '/manager-users-table'},
      ];
    }
    this.setMenuItems().then();
    this.closeMenu();
  }

  private closeMenu = (): void => {
    this.fixedFullMenuHandler(false);
    this.showFullMenu = false
  }

  setInnerScreenWidth() {
    this.screenWidth = window.innerWidth;
  }

  onToggleEmit() {
    this.onToggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
  }

  fixedFullMenuHandler(status: boolean) {
    this.fixedFullMenu = status;
  }

  private setMenuItems = async (): Promise<void> => {
    const userInfo = localStorage.getItem('userInfo');
    const companyId = JSON.parse(userInfo)?.companyId;
    const companyMenu = await this.firebaseService.getDocumentsListOnce(`companies/${companyId}/settings/dashboardMenu`);
    if (!companyMenu.length) {
      return;
    }
    this.menuItems = this.menuItems.filter(menuItem => !companyMenu.some(companyMenuItem => menuItem.key === companyMenuItem.key && companyMenuItem.hidden));
  }
}
