import { Pipe, PipeTransform } from '@angular/core';
import { CompaniesService } from '../../services/companies.service';
import { AuthService } from '../../services/auth.service';
import { RolePipe } from './role.pipe';

@Pipe({
  name: 'roleCompanySetting'
})
export class RoleCompanySettingPipe implements PipeTransform {
  constructor(
    private companiesService: CompaniesService,
    private authService: AuthService,
    private rolePipe: RolePipe
  ) {
  }
  
  async transform(roles: string[], paths: string[]): Promise<boolean> {
    const validRole = this.rolePipe.transform(roles);
    if (!validRole) {
      return false;
    }
    
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const userRole = this.authService.getUserRole();
    if (userRole === 'admin') {
      return true;
    }
    const requests = [];
    for (const path of paths) {
      requests.push(this.companiesService.getSettingByPath(user.companyId, `roles/${userRole}/${path}`));
    }
    const results = await Promise.all(requests);
    return results.some(result => result);
  }
  
}
