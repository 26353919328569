import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserInfoService } from 'src/app/services/user-info.service';
import { ApplicationValidator } from "../../shared/validators/application.validator";
import { UserCategoryType } from "../../utils/custom-types";
import moment from "moment";
import { ComponentBase } from "../../utils/component-base";
import { RolePipe } from '../../shared/pipes/role.pipe';
import { GenericFunctions } from '../../utils/generic-functions';
import { CompaniesService } from "../../services/companies.service";
import { ModuleEnum } from "../../utils/enums/module.enum";

@Component({
  selector: 'add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: [],
})
export class AddUserDialogComponent extends ComponentBase implements OnInit {

  user: any;
  isAdmin: boolean = true;
  isManager: boolean = true;

  protected companies: { label: string; value: any, isDemo: boolean, domain: string }[] | undefined;
  protected isLoading = true;
  protected emailDomainControl: FormControl;
  protected emailForm: FormGroup;
  protected userCategory: UserCategoryType;
  protected emailLength: number;
  protected emailLimit: number;
  protected isEnabledDepartments: boolean;
  protected departments: any[];
  protected override modules: ModuleEnum[];
  private allCompanies: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
    private userInfoService: UserInfoService,
    private applicationValidator: ApplicationValidator,
    private rolePipe: RolePipe,
    private companyService: CompaniesService
  ) {
    super();
    this.initVariables();
  }

  async ngOnInit(): Promise<void> {
    await this.loadData();
    this.userCategoryHandler('employee').then();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  protected addUser(): void {
    if (this.emailForm.invalid) {
      return;
    }
    const addUserPayload = this.emailForm.getRawValue();
    let companyObj;
    if (this.userCategory === 'demo' || this.userCategory === 'manager') {
      companyObj = this.findCompany(addUserPayload.company)?.value;
      companyObj = companyObj || {
        companyName: this.capitalize(addUserPayload.company),
        name: addUserPayload.company.replace(/ /g, "-").toLowerCase(),
        domain: addUserPayload.emailDomain
      };
    } else {
      companyObj = this.companies.find(x => x.value.code === addUserPayload.company).value;
    }

    const user: any = {
      lang: addUserPayload.lang,
      demo: this.userCategory === 'demo',
      manager: this.userCategory === 'manager',
      company: companyObj,
      emails: addUserPayload.emails, // Use the formatted emails array
      added: moment().format('YYYY-MM-DDTHH:mm:ss'),
      module: addUserPayload.module,
    };
    if (addUserPayload.department) {
      user.department = addUserPayload.department.toString();
    }
    this.dialogRef.close(user);
  }

  protected async userCategoryHandler(userCategory: any): Promise<void> {
    this.isEnabledDepartments = this.isManager;

    this.userCategory = userCategory;
    this.companies = this.getCompaniesByUserCategory(this.userCategory);
    this.initEmailForm();
    this.initEmailInputListener();
    this.emailForm.get('emailInput').disable();

    if (this.userCategory === 'employee') {
      this.emailForm.get('emailDomain').disable();
      this.emailForm.get('role').setValue(null);
    } else if (this.userCategory === 'demo') {
      this.emailForm.get('role').setValue(null);
    } else if (this.userCategory === 'manager') {
      this.emailForm.get('role').setValue('manager');
    }
    await this.initManagerFlow()
  }

  protected async selectCompany(company: any): Promise<void> {
    await this.loadCompanyModules(company?.value?.code);
    await this.loadDepartments(company?.value?.code);
    await this.setEmailInputValidators(company?.value?.code)
    this.emailForm.get('emailInput').enable();
    if (!company.domain) {
      return this.emailForm.get('emailDomain').enable();
    }
    this.emailDomainControl.setValue(company.domain);
    this.emailForm.get('emailDomain').disable();
  }

  private parseEmails(emails: string): string[] {
    const auxEmails = emails.replace(/(?:\r\n|\r|\n|\s|;)/g, ',').split(',').map((email) => email.trim());
    return auxEmails.filter(x => x.trim());
  }

  private async loadData(): Promise<void> {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.isAdmin = this.user.role == "admin";
    this.isManager = this.user.role == "manager";
    this.allCompanies = await this.userInfoService.getCompanies();
    this.isLoading = false;
  }

  private getCompaniesByUserCategory(userCategory: UserCategoryType): any[] {
    const companies = []
    for (const companiesKey in this.allCompanies) {
      const company = this.allCompanies[companiesKey]
      if (userCategory === 'employee' && company.type === 'demo') {
        continue;
      }
      companies.push({
        label: company.name,
        domain: company.domain,
        isDemo: company.type === 'demo',
        value: {name: company.name_lowercase, code: company.code, companyName: company.name_lowercase},
      })
    }
    return companies;
  }

  private capitalize(text: string) {
    const words = text.split(" ");
    for (let i = 0; i < words.length; i++) words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    return words.join(' ');
  }

  private initVariables(): void {
    this.emailLength = 999;
    this.allCompanies = [];
    this.modules = [];
  }

  private initEmailForm(): void {
    this.emailDomainControl = new FormControl('', []);
    this.emailForm = new FormGroup({
      emailInput: new FormControl('', [Validators.required, this.applicationValidator.emailDomains(this.emailDomainControl)]),
      emails: new FormControl('', [Validators.maxLength(this.emailLength), Validators.minLength(1)]),
      demo: new FormControl(false),
      role: new FormControl(null),
      lang: new FormControl('es'),
      company: new FormControl('', [Validators.required]),
      department: new FormControl(''),
      emailDomain: this.emailDomainControl,
      module: new FormControl('', [Validators.required]),
    });

    this.emailDomainControl.statusChanges.subscribe((state) => {
      if (state === 'VALID' || (state === 'DISABLED' && this.emailDomainControl.value)) {
        this.emailForm.get('emailInput').enable();
      } else {
        this.emailForm.get('emailInput').disable();
      }
    });
  }

  private initEmailInputListener(): void {
    this.emailForm.get('emailInput').valueChanges.subscribe((emails) => {
      const parsedEmails = this.parseEmails(emails);
      this.emailForm.get('emails').setValue(parsedEmails);
      if (this.emailForm.get('emails').invalid) {
        this.emailForm.get('emailInput').setErrors({'maxLength': true})
      }
    })
  }

  private findCompany(value: string): any {
    const filterValue = (value || '').replace(/ /g, "-").toLowerCase().trim();
    return this.companies.find(option => option.value?.name?.toLowerCase() === filterValue
      || option.value?.code?.toLowerCase() === filterValue);
  }

  private async initManagerFlow(): Promise<void> {
    if (!this.rolePipe.transform(['manager'])) {
      return;
    }

    const user = JSON.parse(localStorage.getItem('userInfo'));
    const companyKey = user?.companyLowercase;
    const company = this.companies.find(x => x.value.companyName === companyKey);
    this.emailForm.get('company').setValue(company.value.code);
    this.emailForm.get('company').disable();
    this.selectCompany(company).then();
  }

  private async setEmailInputValidators(companyId: string): Promise<void> {
    const skipEmailDomainValidation = await this.firebaseService.getDocument(`companies/${companyId}/settings/register/skipEmailDomainValidation`);
    const emailLimit = await this.firebaseService.getDocument(`companies/${companyId}/settings/register/limit`);
    const validators = [Validators.required];
    if (!skipEmailDomainValidation) {
      validators.push(this.applicationValidator.emailDomains(this.emailDomainControl));
    }
    if (!GenericFunctions.isNullOrUndefined(emailLimit)) {
      this.emailLimit = emailLimit
      const users = await this.countUsers(companyId)
      validators.push(this.applicationValidator.emailLimit(emailLimit - users))
    }
    this.emailForm.get('emailInput').setValidators(validators);
  }

  private loadDepartments = async (companyId: string): Promise<void> => {
    this.departments = [];
    this.emailForm.get('department').reset();
    this.isEnabledDepartments = await this.firebaseService.getDocument(`companies/${companyId}/settings/register/enableDepartments`)
    if (!this.isEnabledDepartments) {
      this.emailForm.get('department').clearValidators();
      this.emailForm.get('department').updateValueAndValidity();
      return;
    }

    this.emailForm.get('department').updateValueAndValidity();
    const departments = await this.firebaseService.getDocumentsListOnce(`companies/${companyId}/departments`);
    this.departments = departments || [];
    this.departments.sort((a, b) => a.name.localeCompare(b.name));

    if (this.isManager) {
      const user = JSON.parse(localStorage.getItem('userInfo'));
      const department = user?.department;
      this.emailForm.get('department').setValue(department);
      this.emailForm.get('department').disable();
    }


  }

  private loadCompanyModules = async (companyId: string): Promise<void> => {
    this.modules = [];
    this.emailForm.get('module').reset();
    this.modules = await this.companyService.getCompanyModules(companyId);
  }

  private async countUsers(companyId: string): Promise<number> {
    const company = this.companies.find(x => x.value.code === companyId);
    const [activeUsers, addedUsers] = await Promise.all([
      this.firebaseService.getDocument(`companies/${company?.value?.code}/users/active`),
      this.firebaseService.getDocumentsListOnce(`added_users/${company?.value?.companyName}`)
    ]);
    return (activeUsers || 0) + addedUsers.length;
  }

}
